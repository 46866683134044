
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import Preloader from '@/views/components/Preloader.vue'
import { axiosApi } from '@/models/http/axiosApi'
import CampaignRequestDto from '@/models/dto/CampaignRequestDto'
import { useStore } from 'vuex'
import redirectTo from '@/helpers/redirectTo'
import { handleVisitorCookie } from '@/helpers/handleVisitorCookie'

export default defineComponent({
  name: 'Campaign',
  components: { Preloader },
  created (): void {
    this.sendCampaignRequest()
  },
  methods: {
    sendCampaignRequest (): void {
      const route = useRoute()
      const store = useStore()
      if (Object.keys(route.query).length === 0) {
        return
      }

      const request = new CampaignRequestDto(route.query)

      axiosApi.post('/campaign-request', request)
        .then(response => {
          this.$cookie.setCookie('campaign_request_id', response.data.id)
          handleVisitorCookie(response.data.id)
        }).finally(() => {
          request.instantForm && store.commit('setOpen', true)
          redirectTo('home')
        }
        )
    }
  }
})
