export default class CampaignRequestDto {
  public readonly externalId: number
  public readonly campaignId: number
  public readonly lendingPageId: number
  public readonly advertisementId: number
  public readonly creativeId: number
  public readonly publisherId: number
  public readonly phone: string
  public readonly clickId: string
  public readonly zipcode: string
  public readonly firstName: string
  public readonly lastName: string
  public readonly email: string
  public readonly state: string
  public readonly product: string
  public readonly source: string
  public readonly subSource: string
  public readonly dynamicOption1: string
  public readonly dynamicOption2: string
  public readonly dynamicOption3: string
  public readonly dynamicOption4: string
  public readonly instantForm: boolean

  constructor (data: Record<string, string | (string | null)[] | null>) {
    this.externalId = data.eid ? Number(data.eid) : 0
    this.lendingPageId = data.lp ? Number(data.lp) : 0
    this.campaignId = data.campaign_id ? Number(data.campaign_id) : 0
    this.advertisementId = data.ad_id ? Number(data.ad_id) : 0
    this.creativeId = data.creative_id ? Number(data.creative_id) : 0
    this.publisherId = data.pub_id ? Number(data.pub_id) : 0
    this.phone = data.phone ? String(data.phone) : '0'
    this.clickId = data.cid ? String(data.cid) : ''
    this.zipcode = data.zip ? String(data.zip) : ''
    this.firstName = data.fname ? String(data.fname) : ''
    this.lastName = data.lname ? String(data.lname) : ''
    this.email = data.email ? String(data.email) : ''
    this.state = data.state ? String(data.state) : ''
    this.product = data.product ? String(data.product) : ''
    this.source = data.source ? String(data.source) : ''
    this.subSource = data.subid ? String(data.subid) : ''
    this.dynamicOption1 = data.s1 ? String(data.s1) : ''
    this.dynamicOption2 = data.s2 ? String(data.s2) : ''
    this.dynamicOption3 = data.s3 ? String(data.s3) : ''
    this.dynamicOption4 = data.s4 ? String(data.s4) : ''
    this.instantForm = data.instant_form ? Boolean(data.instant_form) : false
  }
}
