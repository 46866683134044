
import { defineComponent } from 'vue'
import { useMeta } from 'vue-meta'
import ScrollToMixin from '@/mixins/scrollToDiv.ts'
import { segmentTracking } from '@/helpers/clickEventTracking'
import { axiosApi } from '@/models/http/axiosApi'
import { VueCookieNext } from 'vue-cookie-next'
import redirectTo from '@/helpers/redirectTo'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'WelcomeBack',
  mixins: [ScrollToMixin],
  data () {
    return {
      siteDomain: useStore().state.siteSettings.domain ? useStore().state.siteSettings.domain : '',
      title: useStore().state.siteSettings.title ? useStore().state.siteSettings.title : '',
      client: {},
      spinner: true
    }
  },
  mounted () {
    const clientId = this.$cookie.getCookie('client_id')
    axiosApi.get(`/client/${clientId}`)
      .then((response) => {
        this.client = response.data
        this.spinner = false
      })
  },
  setup () {
    useMeta({
      title: 'Welcome Back'
    })

    const clickEvent = () => {
      segmentTracking('Clicked On Get Quotes')
    }
    const deleteCookie = () => {
      VueCookieNext.removeCookie('client_id')
      redirectTo('home')
    }

    return {
      clickEvent,
      deleteCookie
    }
  }
})
